import React from "react";
import LineStyleIcon from '@mui/icons-material/LineStyle';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import MessageIcon from '@mui/icons-material/Message';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ReportIcon from '@mui/icons-material/Report';

import { Link } from 'react-router-dom';

import './Sidebar.css';

function Sidebar() {
    return(
        <>
            <div className="sidebar">
                <div className="sidbarWrapper">

                    <div className="sidebarMenue">
                        <h3 className="sidebarTitle">
                            داشبورد
                        </h3>
                        <ul className="sidebarList">
                            <Link to='/' className="link">
                                <li className="sidebarListItem active">
                                    <LineStyleIcon />
                                    خانه
                                </li>
                            </Link>
                            {/*<li className="sidebarListItem">
                                <TimelineIcon />
                                Analiytics
                            </li>*/}
                        </ul>
                    </div>

                    <div className="sidebarMenue">
                        <h3 className="sidebarTitle">
                            منوی دسترسی
                        </h3>
                        <ul className="sidebarList">
                        <Link to='/users' className="link">
                                <li className="sidebarListItem">
                                    <PermIdentityIcon />
                                    کاربران
                                </li>
                            </Link>
                        <Link to='/physician' className="link">
                                <li className="sidebarListItem">
                                    <PermIdentityIcon />
                                    پزشکان
                                </li>
                            </Link>
                            <Link to='/patient' className="link">
                                <li className="sidebarListItem">
                                    <PermIdentityIcon />
                                    بیماران
                                </li>
                            </Link>
                            <Link to='/Exercise' className="link">
                                <li className="sidebarListItem">
                                    <StorefrontIcon />
                                    تمرینات
                                </li>
                            </Link>
                            <Link to='/Education' className="link">
                                <li className="sidebarListItem">
                                    <StorefrontIcon />
                                    آموزش ها
                                </li>
                            </Link>
                           {/* <Link to='/packages' className="link">
                                <li className="sidebarListItem">
                                    <AttachMoneyIcon />
                                    Packages
                                </li>
                            </Link>
                             <li className="sidebarListItem">
                                <BarChartIcon />
                                reports
                            </li> */}
                        </ul>
                    </div>
{/*
                    <div className="sidebarMenue">
                        <h3 className="sidebarTitle">
                            Notifications
                        </h3>
                        <ul className="sidebarList">
                            <li className="sidebarListItem">
                                <MailOutlineIcon />
                                Mail
                            </li>
                            <li className="sidebarListItem">
                                <DynamicFeedIcon />
                                Feadback
                            </li>
                            <li className="sidebarListItem">
                                <MessageIcon />
                                Messages
                            </li>
                        </ul>
                    </div>

                    <div className="sidebarMenue">
                        <h3 className="sidebarTitle">
                            Staff
                        </h3>
                        <ul className="sidebarList">
                            <li className="sidebarListItem">
                                <ManageHistoryIcon />
                                Manage
                            </li>
                            <li className="sidebarListItem">
                                <TimelineIcon />
                                Analytics
                            </li>
                            <li className="sidebarListItem">
                                <ReportIcon />
                                Reports
                            </li>
                        </ul>
                    </div>*/}

                </div>
            </div>
        </>
    )
}

export default Sidebar