import React, { createContext, useState } from 'react';

export const SelectedGroup = createContext();

const SelectedGroupProvider = ({ children }) => {
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedGroupsList, setSelectedGroupsList] = useState([]);
    const [selectedtgrp, setselectedtgrp] = useState({});
    
  const updateExerSelectedGroupVariable = (newValue) => {
    // localStorage.setItem('storedPackageValue', newValue);
    setselectedtgrp(newValue);
   };

  return (
    <SelectedGroup.Provider value={{selectedGroup, setSelectedGroup, selectedGroupsList, setSelectedGroupsList,selectedtgrp,setselectedtgrp,updateExerSelectedGroupVariable}}>
      {children}
    </SelectedGroup.Provider>
  );
};

export { SelectedGroupProvider };