//import React from "react"
import Featureas from "../../Components/featureas/Featureas";
import Chart from "../../Components/chart/Chart";
import { xAxisData } from "../../datas";
import WidgetSm from "../../Components/widgetSm/WidgetSm";
import WidgetLg from "../../Components/WidgetLg/WidgetLg";
import axios from "axios";
//import './Home.css'
//import './t.js'
//import './t.css'
import config from "../../config";

import React, { useEffect, useState } from "react";
import "./Home.css";
import Modal from "../../Components/Modal/Modal";

const Package = [
  {
    keyID: 1062,
    packageName: "پکيج 1",
    packageDescription: "string",
    exercises: [
      {
        keyID: 1,
        exercisesName: "تمرین شماره 1 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "1.jpg",
      },
      {
        keyID: 2,
        exercisesName: "تمرین شماره 2 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "1.jpg",
      },
    ],
    educations: [
      {
        keyID: 1062,
        educationName: "آموزش شماره 1 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "1.jpg",
      },
      {
        keyID: 1064,
        educationName: "آموزش شماره2 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "1.jpg",
      },
    ],
  },
  //package2 :
  {
    keyID: 2,
    packageName: "پکيج 2",
    packageDescription: "توضیحات پکیج 2",
    exercises: [
      {
        keyID: 3,
        exercisesName: "تمرین شماره 3 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "2.jpg",
      },
      {
        keyID: 4,
        exercisesName: "تمرین شماره 4 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "2.jpg",
      },
    ],
    educations: [
      {
        keyID: 3,
        educationName: "آموزش شماره 3 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "2.jpg",
      },
      {
        keyID: 4,
        educationName: "آموزش شماره4 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "2.jpg",
      },
    ],
  },
  //package3 :
  {
    keyID: 3,
    packageName: "پکيج 3",
    packageDescription: "توضیحات پکیج 3",
    exercises: [
      {
        keyID: 5,
        exercisesName: "تمرین شماره 5 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "3.jpg",
      },
      {
        keyID: 6,
        exercisesName: "تمرین شماره 6 ",
        exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
        exercisesDesc: "اینجا نکات تمرین نوشته می شود",
        ExcMainfilepath: "3.jpg",
      },
    ],
    educations: [
      {
        keyID: 5,
        educationName: "آموزش شماره 5 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "3.jpg",
      },
      {
        keyID: 6,
        educationName: "آموزش شماره6 ",
        educationDesc: "اینجا توضیحات آموزش نوشته می شود",
        EduMainfilepath: "3.jpg",
      },
    ],
  },
];
const selectedPackageExercises = [
  {
    keyID: 1,
    exercisesName: "تمرین شماره 1 ",
    exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
    exercisesDesc: "اینجا نکات تمرین نوشته می شود",
    ExcMainfilepath: "1.jpg",
  },
  {
    keyID: 2,
    exercisesName: "تمرین شماره 2 ",
    exercisesExecution: "نحوه اجرای این تمرین یه این صورت است",
    exercisesDesc: "اینجا نکات تمرین نوشته می شود",
    ExcMainfilepath: "1.jpg",
  },
];

const selectedexerciseFiles = [
  {
    keyID: 1021,
    fileTitle: "عنوان ویدئو 1",
    filesAddress: "20240220105726_Bridge(2).mp4",
    fileType: "mp4",
    isImage: false,
  },
  {
    keyID: 159,
    fileTitle: "عنوان عکس 1",
    filesAddress: "1111.jpg",
    fileType: "jpg",
    isImage: true,
  },
  {
    keyID: 160,
    fileTitle: "عنوان عکس 2",
    filesAddress: "2222.jpg",
    fileType: "jpg",
    isImage: true,
  },
  {
    keyID: 161,
    fileTitle: "عنوان عکس 3",
    filesAddress: "3333.jpg",
    fileType: "jpg",
    isImage: true,
  },
];

const selectedEducationFiles = [
  {
    keyID: 1022,
    fileTitle: "عنوان ویدئو 1",
    filesAddress: "e11.mp4",
    fileType: "mp4",
    isImage: false,
  },
  {
    keyID: 162,
    fileTitle: "عنوان عکس 1",
    filesAddress: "e1.jpg",
    fileType: "jpg",
    isImage: true,
  },
  {
    keyID: 163,
    fileTitle: "عنوان عکس 2",
    filesAddress: "e2.jpg",
    fileType: "jpg",
    isImage: true,
  },
  {
    keyID: 164,
    fileTitle: "عنوان عکس 3",
    filesAddress: "e3.jpg",
    fileType: "jpg",
    isImage: true,
  },
];

const selectedexerciseParameters = [
  {
    keyID: 1979,
    paramName: "عنوان پارامتر  1",
    paramValue: "مقدار پارامتر  1",
  },
  {
    keyID: 1980,
    paramName: "عنوان پارامتر  2",
    paramValue: "مقدار پارامتر  2",
  },
  {
    keyID: 1981,
    paramName: "عنوان پارامتر  3",
    paramValue: "مقدار پارامتر  3",
  },
  {
    keyID: 1982,
    paramName: "عنوان پارامتر  4",
    paramValue: "مقدار پارامتر  4",
  },
];

function Home() {
  const [packages, setPackages] = useState([]);
  const [selectedPackageExercises, setselectedPackageExercises] = useState([]);
  const [selectedexerciseParameters, setselectedexerciseParameters] = useState(
    []
  );
  //selectedexerciseParameters
  const [exerciseParameters, setExerciseParameters] = useState([]);
  const [filesss, setFilesss] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const getPackageFromAPI = () => {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const userId = getUserIdFromYourAuthenticationSystem(authToken);
    console.log(userId + "pppp");
    axios
      .get(
        config.hostname + `/api/v1/Patient/${userId / 100}/Packages/GetActive`,
        {
          headers,
        }
      )
      .then((response) => {
        //  const patientData = response.data.patientModelList;

        console.log(response.data);
        const packages2 = response.data.map((item) => ({
          keyID: item.keyID,
          packageName: item.packageName,
          packageDescription: item.packageDescription,
          exercises: [], // You may populate this array based on other data
          educations: [], // You may populate this array based on other data
        }));

        setPackages(packages2);
        //  setpatientList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };
  const getUserIdFromYourAuthenticationSystem = (authToken) => {
    // Decode the JWT token to extract user information
    const tokenParts = authToken.split(".");
    if (tokenParts.length !== 3) {
      // Invalid JWT token format
      return null;
    }

    const payload = JSON.parse(atob(tokenParts[1]));
    // Assuming the user ID is stored in the JWT payload as `userId`
    // console.log(payload);
    const userId = payload.UserName;
    //console.log(userId+"lll");
    return userId;
  };
  const getExerciseBypkg = (PkgID) => {
    ///api/v1/Package/1022/Exercises/1/Parameters

    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    axios
      .get(config.hostname + `/api/v1/Package/${PkgID}/Exercises`, { headers })
      .then((response) => {
        //  const patientData = response.data.patientModelList;

        console.log(response.data);
        setselectedPackageExercises(response.data);
        console.log(response.data);

        // setPackages(packages2)
        //  setpatientList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const getParametersBypkgandexerc = (PkgID, exerciseID) => {
    ///api/v1/Package/1022/Exercises/1/Parameters
    ///api/v1/Exercise/103/ExerciseFiles

    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    ///api/v1/Package/1170/Exercises/106/Parameters
    //api/v1/Package/${PkgID}/Exercises/${exerciseID}/Parameters
    axios
      .get(
        config.hostname +
          `/api/v1/Package/${PkgID}/Exercises/${exerciseID}/Parameters`,
        { headers }
      )
      .then((response) => {
        //  const patientData = response.data.patientModelList;

        setselectedexerciseParameters(response.data);
        //console.log(response.data);

        // setPackages(packages2)
        //  setpatientList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const getfiles = (ISexcer, keyID) => {
    ///api/v1/Package/1022/Exercises/1/Parameters
    ///api/v1/Exercise/103/ExerciseFiles
    let apiaddress = "";
    if (ISexcer) {
      apiaddress = `/api/v1/Exercise/${keyID}/ExerciseFiles`;
    } else {
      apiaddress = `/api/v1/Education/${keyID}/EducationFiles`;
    }

    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    ///api/v1/Package/1170/Exercises/106/Parameters
    //api/v1/Package/${PkgID}/Exercises/${exerciseID}/Parameters
    axios
      .get(config.hostname + apiaddress, { headers })
      .then((response) => {
        //  const patientData = response.data.patientModelList;

        setselectedexerciseParameters(response.data);
        setFilesss(response.data);
        //console.log(response.data);

        // setPackages(packages2)
        //  setpatientList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  //const getPackageFromAPI = () => {
  // axios.get('/api/v1/Patient/200/Packages')
  //    .then(response => {
  //      // Handle successful response
  //     console.log(response.data);
  // })
  // .catch(error => {
  //   // Handle error
  //      console.error('There was a problem with the request:', error);
  //  });
  //};

  useEffect(() => {
    // Fetch or set your default JSON data here
    // For now, using the provided `Package` array
    getPackageFromAPI();
    setPackages(Package);
    setExerciseParameters(selectedexerciseParameters);
  }, []);

  function showDetails(id, pkgid, exerid) {
    var details = document.getElementById(id);
    console.log("style is : " + exerid);

    if (details.classList.contains("active")) {
      details.classList.remove("active");

      // setIsDetailsOpen(false);
    } else {
      details.classList.add("active");
      if (exerid == -1) getExerciseBypkg(pkgid);
      else {
        //  getExerciseBypkg(pkgid);
        getParametersBypkgandexerc(pkgid, exerid);
      }
      // setIsDetailsOpen(true);
    }
  }

  function showModal(isexcer, keyID) {
    getfiles(isexcer, keyID);
    console.log(filesss);
  }

  function closeModal() {
    setFilesss([]);
  }

  const FormatExecutionString = (inputstr) => {
    // Check if inputstr is defined and is a string
    if (typeof inputstr !== "string") {
      console.error("Invalid input. Please provide a valid string.");
      return "";
    }
    //console.log(inputstr);
    const steps = inputstr.split("\n");

    // Map each step to an <li> element with the specified style
    const formattedSteps = steps.map(
      (step) =>
        step &&
        `<li style="margin: 10px; list-style: inside;">${step.trim()}</li>`
    );

    // Join the formatted steps into a string
    const result = formattedSteps.join("\n");
    // console.log(result);
    return result;
  };

  return (
    <>
      {packages.length > 0 ? (
        <div className="package-container">
          {packages.map((pkg) => (
            <div className="package" key={pkg.keyID}>
              <div className="package-header">
                <h2>{pkg.packageName}</h2>
                {/* <h5>1402/10/10</h5> {/* Add this line for displaying the date */}
              </div>

              {/* <p>{pkg.packageDescription}</p>*/}
              <button
                onClick={() =>
                  showDetails(`details${pkg.keyID}`, pkg.keyID, -1)
                }
              >
                {isDetailsOpen ? "بستن جزئیات" : "مشاهده جزئیات"}
              </button>
              <div id={`details${pkg.keyID}`} className="details">
                {selectedPackageExercises.map((exercise) => (
                  <div key={exercise.keyID} className="exercise-box">
                    <div className="exercise">
                      <div className="div1">
                        <img src={exercise.mainPicturePath} />
                      </div>
                      <div className="div2">
                        <br></br>
                        <p>
                          <span>عنوان تمرین:</span> {exercise.name}
                        </p>
                        <p>
                          <span>نکات تمرین:</span>{" "}
                          <ul
                            dangerouslySetInnerHTML={{
                              __html: FormatExecutionString(
                                exercise.preparation
                              ),
                            }}
                          ></ul>
                        </p>
                        <br></br>
                      </div>
                      <div className="div3">
                        <button
                          onClick={() =>
                            showDetails(
                              `mydiv${exercise.keyID}-${pkg.keyID}`,
                              pkg.keyID,
                              exercise.keyID
                            )
                          }
                          className="button"
                          id="mybutton"
                        >
                          {" "}
                          مشاهده
                        </button>
                      </div>
                    </div>
                    <div
                      id={`mydiv${exercise.keyID}-${pkg.keyID}`}
                      className="mydiv"
                      display="none"
                    >
                      <div className="exercise-parameters">
                        {selectedexerciseParameters.map((parameter) => (
                          <div key={parameter.keyID}>
                            <span className="param-name">
                              {parameter.paramName}
                            </span>
                            : <span>{parameter.paramValue}</span>
                          </div>
                        ))}
                      </div>
                      <button onClick={() => showModal(true, exercise.keyID)}>
                        ویدئو و تصاویر
                      </button>
                    </div>
                  </div>
                ))}
                {pkg.educations.map((educ) => (
                  <div key={educ.keyID} className="education">
                    <div className="div1">
                      <img src={educ.EduMainfilepath} />
                    </div>
                    <div className="div2">
                      <p>
                        <span>عنوان آموزش:</span> {educ.educationName}
                      </p>
                    </div>
                    <div className="div3">
                      <button
                        onClick={() => showModal(false, educ.keyID)}
                        className="button"
                        id="myButton"
                      >
                        مشاهده
                      </button>
                    </div>
                    {/* <p>آدرس فایل: {educ.EduMainfilepath}</p> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : <p className="no-package-message">پکیجی یافت نشد</p>}
      {filesss.length !== 0 && <Modal files={filesss} onClose={closeModal} />}
    </>
  );
}
export default Home;
