import React, {useEffect} from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

export default function Modal({ files, onClose }) {

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if(e.target.className === 'modal'){
        onClose()
      }
    })

    return () => {
      window.removeEventListener('click', () => {})
    }
  }, [])
  return createPortal(
    <div className="modal">
      <div className="modal-box">
        {files.map((file) => (
          <div key={file.keyID} className="single-file">
         {/* <h2>{file.fileTitle}</h2>*/} 
            {file.isImage ? (
              <div className="img-container">
                <img src={file.filesAddress} alt="" />
              </div>
            ) : (
              <video controls>
                <source
                  src={file.filesAddress}
                  type={`video/${file.fileType}`}
                />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
        <button onClick={() => onClose()}>بستن</button>
      </div>
    </div>,
    document.getElementById("modal")
  );
}
