import Home from './pages/Home/Home'
//import PhysicianList  from './pages/Physicians/PhysiciansList'
///import PatientList  from './pages/Patients/PatientsList'
//import UserList from './pages/Users/UsersList'
//import Exerciselist from './pages/Exercises/Exerciseslist'
//import Educationlist from './pages/Educations/Educationslist'
//import Products from './pages/Products/Products'
//import Product from './pages/Product/Product'
import SignInSide from "./pages/LoginPage/Login";


let routes = [
    {path: '/', element: <Home />},
  //  {path: '/users', element: <UserList />},
   // {path: '/physician', element: <PhysicianList />},
   // {path: '/patient', element: <PatientList />},
   // {path: '/Exercise', element: <Exerciselist />},
   // {path: '/Education', element: <Educationlist />},
   // {path: '/packages', element: <Products />},
   // {path: '/product/:id', element: <Product />},
    { path: '/link/:code', element: <SignInSide /> },
]

export default routes